import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
const createFocusTrap = require('focus-trap/index.js');

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Header {
  constructor(mobileBP = 767) {
    this.headerEl = document.getElementById('Header');
    this.menuOverlayEl = this.headerEl.querySelector('.Header-overlay');
    this.navEl = this.headerEl.querySelector('.Header-content');
    this.menuEl = this.headerEl.querySelector('.Header-menu');
    this.menuBtnEl = this.headerEl.querySelector('.Header-toggleIcon');
    this.menuItems = this.headerEl.querySelectorAll('.Header-menuItem a');
    this.hasBeenSetupMobile = false;
    this.mobileBP = window.matchMedia(`(max-width: ${mobileBP}px)`);
    this.wasDesktop = !this.mobileBP.matches;
  }
}
