import { ReverseGeolocation } from 'js/components/Util/ReverseGeolocation.js';
ReverseGeolocation.initClass();

import { HTML5Geolocation } from 'js/components/Util/Html5Geolocation.js';
HTML5Geolocation.initClass();

import device from 'current-device';

export default function(isLocation, cbpgFunction) {
  let cbdevice = device.tablet() ? 'tablet' : (device.mobile() ? 'mobi' : 'desk');
  let cbgeoLocation;
  const cbbrand = window.location.hostname.includes("citizensbank") ? 'cb' : 'co';
  let cbnmls = "";
  if (isLocation) {
    cbnmls = JSON.parse(document.getElementById("NMLS").innerHTML).nmls;
  }
  if (cbdevice === 'mobi') {
    if (navigator && navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({name: 'geolocation'}).then(result => {
        if (result.state == 'granted') {
          HTML5Geolocation.getCurrentLocation(position => {
            if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
              cbgeoLocation = `${position.latitude},${position.longitude}`;
              formAndAppendScripts(cbdevice, cbgeoLocation, cbbrand, cbnmls, cbpgFunction);
            }
            else {
              formAndAppendScripts(cbdevice, "", cbbrand, cbnmls, cbpgFunction);
            }
          }, () => {
            formAndAppendScripts(cbdevice, "", cbbrand, cbnmls, cbpgFunction);
          })
        }
        else {
          ReverseGeolocation.getCurrentLocation(async (position) => {
            if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
              cbgeoLocation = `${position.latitude},${position.longitude}`;
              formAndAppendScripts(cbdevice, cbgeoLocation, cbbrand, cbnmls, cbpgFunction);
            }
            else {
              formAndAppendScripts(cbdevice, "", cbbrand, cbnmls, cbpgFunction);
            }
          })
        }
      })
    }
    else {
      ReverseGeolocation.getCurrentLocation(async (position) => {
        if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
          cbgeoLocation = `${position.latitude},${position.longitude}`;
          formAndAppendScripts(cbdevice, cbgeoLocation, cbbrand, cbnmls, cbpgFunction);
        }
        else {
          formAndAppendScripts(cbdevice, "", cbbrand, cbnmls, cbpgFunction);
        }
      })
    }
  }
  else {
    formAndAppendScripts(cbdevice, "", cbbrand, cbnmls, cbpgFunction);
  }
}

function formAndAppendScripts(cbdevice, cbgeoLocation, cbbrand, cbnmls, cbpgFunction) {
  const head = document.getElementsByTagName("head")[0];
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
    var CBDL = CBDL || {};
    CBDL.pgInfo = CBDL.pageInfo || {};
    CBDL.pgInfo = {
        "cbcountry": "us",
        "cblanguage": "eng",
        "cbtype": "consumer",
        "cbdevice": "${cbdevice}",
        "cbgeoLocation": "${cbgeoLocation}",
        "cbpgName":"view",
        "cbpgFunction": "${cbpgFunction}",
        "cbpgSubFunction": "",
        "cbpgType": "loanofficer",
        "cbproductType": "",
        "cbwebidentifier": "marketing",
        "cbaction": "view",
        "cbproduct": "mortgage",
        "cbbrand": "${cbbrand}",
        "cbnmls": "${cbnmls}"
    }
  `;
  const script2 = document.createElement("script");
  let isStaging = JSON.parse(document.getElementById("NMLS").innerHTML).isStaging;
  script2.src = isStaging ? "https://assets.adobedtm.com/c6a477a8a7f5/ef1c20ddf031/launch-7da956692e7c-development.min.js" : "https://assets.adobedtm.com/c6a477a8a7f5/ef1c20ddf031/launch-4974893ce4ad.min.js";

  const pageBottom = "_satellite.pageBottom();"
  const script3 = document.createElement("script");
  script3.setAttribute("type", "text/javascript");
  script3.appendChild(document.createTextNode(pageBottom));

  head.insertBefore(script, head.firstChild);
  script.parentNode.insertBefore(script2, script.nextSibling);

  // Addding script before the closing body tag
  window.onload = function() {
    document.body.appendChild(script3);
  };
}
